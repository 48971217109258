import './App.css';
import { useState, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { ReactComponent as Brand } from './icons/logoED.svg';
import { ReactComponent as Burger } from './icons/hamburger.svg';
import './navbar.scss';
import './footer.scss';
import logoED from './icons/logoED.svg';
import Accueil from "./components/Home/index.js";
import About from "./components/About/index.js";
import Talent from "./components/Talent/index.js";
import Reglementations from "./components/Reglementations/index.js";
import CardList from "./components/Realisations/index.js";
import Contact from "./components/Contact/index.js";

function App() {

  const [showNavbar, setShowNavbar] = useState(false)
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  };

  const accueil = useRef(null);
  const about = useRef(null);
  const talent = useRef(null);
  const realisations = useRef(null); 
  const reglementations = useRef(null);
  const contact = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

    return (
    <div className="App">
      <nav className="navbar">
      <div className="container">
        <div className="logo" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
          <Brand />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Burger />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/" onClick={() => scrollToSection(accueil)}>Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/qui-suis-je" onClick={() => scrollToSection(about)}>Qui Suis-Je</NavLink>
            </li>
            <li>
              <NavLink to="/savoir-faire" onClick={() => scrollToSection(talent)}>Savoir Faire</NavLink>
            </li>
            <li>
              <NavLink to="/réalisations" onClick={() => scrollToSection(realisations)}>Réalisations</NavLink>
            </li> 
            <li>
              <NavLink to="/réglementations" onClick={() => scrollToSection(reglementations)}>Réglementations</NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={() => scrollToSection(contact)}>Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
      <div ref={accueil}>< Accueil /></div>
      <div ref={about}>< About  /></div>
      <div ref={talent}>< Talent  /></div>
      <div ref={realisations}>< CardList /></div>
      <div ref={reglementations}>< Reglementations /></div>
      <div ref={contact}>< Contact /></div>
    <div className='footer'>
      <ul className='footer-lists'>
        <li className='footer-list'>
          <Link to="/" onClick={() => scrollToSection(accueil)}>Accueil</Link>
        </li>
        <li className='footer-list'>
          <Link to="/qui-suis-je" onClick={() => scrollToSection(about)}>Qui Suis-Je</Link>
        </li>
        <li className='footer-list'>
          <Link to="/savoir-faire" onClick={() => scrollToSection(talent)}>Savoir Faire</Link>
        </li>
        <li className='footer-list'>
          <Link to="/réalisations" onClick={() => scrollToSection(realisations)}>Réalisations</Link>
        </li>
        <li className='footer-list'>
          <Link to="/réglementations" onClick={() => scrollToSection(reglementations)}>Réglementations</Link>
        </li>
        <li className='footer-list'>
          <Link to="/contact" onClick={() => scrollToSection(contact)}>Contact</Link>
        </li>
      </ul>
        <div className='footer-logo'>
          <img className='footer-img' src={logoED} alt="logo-footer" />
        </div>
        <div>
          <p className='footer-copyright'> © 2023 LR-DEV | Tous droits réservés</p>
        </div>
    </div>
    </div>
  );
}


export default App;
