import React from 'react';
import "./styles.scss";

function Card({ title, image, handleClick }) {
  return (
    <div className="card" onClick={handleClick}>
      <img className="card-img" 
      src={image}
      alt={title} />
      <h2 className="card-title">{title}</h2>
      <button className='card-btn'>En savoir plus</button>
    </div>
  );
}

export default Card;
