import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Helmet>
        <title>Emilie Delage | Dessinatrice en Batiment | Experte en démarches administratives et autorisations d'urbanisme ile d'oléron</title>
        <meta
        name="description"
        content="Dessinatrice en batiments. Elaborations et dépôt de vos permis de construire et déclarations préalables, dossier d'urbanisme, métrés, vues 3D et démarches administratives."
        />
      </Helmet> 
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
