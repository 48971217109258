import React from "react";
import "./styles.scss";
import facebook from "../../icons/facebook-min.png";
import instagram from "../../icons/instagram-min.png";
import telephone from "../../icons/telephone.png";
import email from "../../icons/email.png";
import maison from "../../icons/maison.png";


function Contact() {
  return (
    <div className="contact">
        <div className="contact-component">
            <h2 className="contact-title">Contact</h2>
            <h3 className="contact-name">Emilie Delage</h3>
            <ul className="contact-lists">
                <li className="contact-list">
                    <img className="contact-list-icon" src={maison} alt="logo adresse"/>
                    <p className="contact-list-text">167, Rue du canal Chaucre, 17190, Saint Georges D'Oléron </p>
                </li>
                <li className="contact-list">
                    <img className="contact-list-icon" src={email} alt="logo E-mail"/>
                    <p className="contact-list-text">ed.plansoleron@gmail.com</p>
                </li>
                <li className="contact-list">
                    <img className="contact-list-icon" src={telephone} alt="logo téléphone"/>
                    <p className="contact-list-text">06.98.43.01.28</p>
                </li>
            </ul>
            <ul className="contact-logo-lists">
                <li className="contact-logo-list">
                    <a href="https://www.facebook.com/profile.php?id=100086615574092">
                        <img className="contact-logo" src={facebook} alt="logo réseau social facebook" />
                    </a>
                </li>
                <li className="contact-logo-list">
                    <a href="https://www.instagram.com/emiliedelageio">
                        <img className="contact-logo" src={instagram} alt="logo réseau social instagram" />
                    </a>
                </li>
            </ul>
        </div>
        
    </div>
  );
}

export default Contact;
