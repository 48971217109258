import React, { useState } from 'react';
import "./styles.scss";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import Card from './Card';
import img1 from "../../icons/AVenir-min.jpg";
import abriJardin from "../../icons/AbriJardin-min.jpg";
import abriModelise from "../../icons/AbriModélisé-min.jpg";
import maisonAvant from "../../icons/MaisonOleronAvant-min.png";
import maisonArriere from "../../icons/MaisonOleronArriere-min.png";
import extension from "../../icons/extension.jpg";
import extension2 from "../../icons/extension2.jpg";
import img2 from "../../icons/card2-min.jpg";
import ImageModal from "../ImageModal/index.js";

function CardList() {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClick = (card) => {
    setSelectedCard(card);
  };

  const cards = [
    { id: 1, title: 'Abri de jardin', image: abriJardin, contenu: 'L’abri de jardin modélisé pour l’autorisation d’urbanisme, puis une photo du même abri une fois construit. Il comprend des lames verticales en bois peint et des tuiles à emboîtement, à l’image des cabanes ostréicoles de l’île d’Oléron.', picture: abriModelise },
    { id: 2, title: 'Maison Oléronaise', image: maisonAvant, contenu: 'La modélisation 3D permet de bien visualiser une future construction. Ici, une maison qui respecte l’aspect traditionnel de l’île d’Oléron: enduit blanc, tuiles à emboîtement, pente de toit entre 28 et 33%, ouvertures plus hautes que larges et volets bois.', picture: maisonArriere },
    { id: 3, title: 'Extension', image: extension, contenu: "Lorsqu'une extension est envisagée sur une maison existante, un dossier d'urbanisme doit être déposé en mairie: un permis de construire ou une déclaration préalable suivant la taille de l'agrandissement envisagé. Les plans de façades font partie des pièces indispensables à fournir au dossier.", picture: extension2 },
    { id: 4, title: 'Projet à venir', image: img1, contenu: 'Prochainement de nouveaux projets à venir !',picture: img2 },
  ];

  return (
  <div className='realisations'>
    <h2 className='realisations-title'>- Réalisations -</h2>
      <div className='realisations-card'>
      {cards.map((card) => (
        <Card
          key={card.id}
          title={card.title}
          image={card.image}
          picture={card.picture}
          handleClick={() => handleClick(card)}
        />
      ))}
      </div>
      {selectedCard && (
        <div className='realisations-card-selected'>
          <ImageModal  src={selectedCard.image} alt={selectedCard.title} />
          <div className='realisations-card-selected-element'>
            <h3 className='realisations-card-selected-title'>{selectedCard.title}</h3>
            <p className='realisations-card-selected-text'>{selectedCard.contenu}</p>
            <ImageModal className='realisations-card-selected-picture' src={selectedCard.picture} alt={selectedCard.title} />
          </div>
        </div>
      )}
      <div className='realisations-element'>
        <div className='realisations-map'>
        <MapContainer center={[47.093970, 0.369840]} zoom={7} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[14.514071, -61.082073]}></Marker>
            <Marker position={[45.744175, -0.633389]}></Marker>
            <Marker position={[46.152744, -1.093788]}> </Marker>
            <Marker position={[45.847459, -1.147392]}></Marker>
            <Marker position={[48.799660, 2.173231]}></Marker>
            <Marker position={[45.942759, -1.307189]}></Marker>
            <Marker position={[46.014664, -1.349118]}></Marker>
            <Marker position={[45.961174, -1.378412]}></Marker>
            <Marker position={[45.972756, -1.378459]}></Marker>
            <Marker position={[45.959886, -1.323709]}></Marker>
            <Marker position={[45.882146, -1.255101]}></Marker>
            <Marker position={[45.841986, -1.207759]}></Marker>
            <Marker position={[45.861295, -1.238474]}></Marker>
            <Marker position={[45.933149, -1.294946]}></Marker>
            <Marker position={[45.893783, -1.224483]}></Marker>
            <Marker position={[45.909066, -1.239821]}></Marker>
            <Marker position={[45.940034, -1.337281]}></Marker>
            <Marker position={[45.984069, -1.310069]}></Marker>
            <Marker position={[45.969303, -1.295433]}></Marker>
            <Marker position={[45.872397, -1.206990]}></Marker>
            <Marker position={[45.915608, -1.299293]}></Marker>
            <Marker position={[45.936916, -1.356848]}></Marker>
            <Marker position={[45.972325, -1.350157]}></Marker>
          </MapContainer>
        </div>
    </div>
  </div>
  );
}

export default CardList;