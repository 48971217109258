import React from "react";
import "./styles.scss";
import fond from "../../icons/fondaccueil-min.jpg";
import fond480 from "../../icons/fondaccueil-min480px.jpg";
import facebook from "../../icons/facebook-min.png";
import instagram from "../../icons/instagram-min.png";

function accueil() {

  return (
    <div className="accueil">
      <div className="accueil-background">
        <img className="accueil-img" 
          src={fond} 
          srcSet={`${fond480} 480w`}
          sizes="(max-width: 480px)"
          alt="background page accueil"
        />
      </div>
      <div className="accueil-content">
        <h1 className="accueil-title">Emilie Delage</h1>
        <h2 className="accuei-subtitle">Dessinatrice en batiment</h2>
        <div className="accueil-group">
          <ul className="accueil-listes">
            <li className="accueil-list">
              <a href="https://www.facebook.com/profile.php?id=100086615574092">
                <img className="accueil-logo" src={facebook} alt="logo réseau social Facebook" />
              </a>
            </li>
            <li className="accueil-list">
              <a href="https://www.instagram.com/emiliedelageio">
                <img className="accueil-logo" src={instagram} alt="logo réseau social Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
   </div>
  );
}

export default accueil;
